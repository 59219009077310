body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body {
  height: 100%;
  background-color: #343330;
}
a {
  color: #D9DCD6;
}

.App {
  text-align: center;
  color: #D9DCD6;

}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: calc(10px + 2vmin);
  justify-content: right;
}

.App-link {
  color: #61dafb;
}
.red-block {
  fill: #2E4C68; /*A4343A*/
  width: 5000px;
  position: absolute;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  z-index:1;
  background-color: #3B393F;
}

.nav{
  font-family: "Fira Sans", sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  flex-direction: row;
  align-items: flex-end;
  justify-content: right;
  list-style: none;
  flex-grow: 1;
  display:none;
}
.logo{
    text-align: left;
  margin: 0 20px;
}
.nav-list {
  float: right;
  direction: ltr;
  margin: 10px 30px;
}

.nav-list li {
  display: inline-flex;
  margin: 10px 10px 10px auto;

}
.nav-list a {
  margin-left: 20px;
  display: inline-flex;
  color: #D9DCD6;
  text-decoration: none;

}

.content {
  z-index: 2;
  position: relative;
  height: 100%;
}

section {
  text-align: left;
  margin: 20px auto;
  padding: 0 15px;
  max-width: 750px;
}
#contact {
  text-align: center;
}
#contact ul {
  padding:0;
  list-style:none;

}
#contact li {
  margin-right: 10px;
  text-decoration:none;
  display: inline-block;
}

#contact i {
  margin-right: 5px;
}

section h2 {
  text-transform: uppercase;
  text-align: center;
}

#dev-icons {
  text-align: center;
  word-break:break-all;
}
#dev-icons i {
  font-size: 45px;
  margin: 5px;
}
section p {
  color: lightgrey;
  font-weight: 400;
  font-family: "Libre Baskerville", Georgia, "Times New Roman", Times, serif;
  line-height: 1.8em;
  margin-bottom: 16.5px;
  font-size: 16px;
  letter-spacing: 0.02em;
}

.logo{
  height: 120px;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

